.carousel-item img {
  width: 100%;
}
.small-carousel .carousel-item img {
  height: 700px; /* Reduce the height as needed */
  object-fit: contain; /* Ensure the image covers the space without distorting */
}

@media (max-width: 768px) {
  .small-carousel .carousel-item img {
    height: 250px; /* Smaller height for mobile */
  }
}