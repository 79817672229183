.news-detail-page {
  padding: 20px;
}

.news-detail-card {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px; /* Köşeleri yuvarla */
  overflow: hidden; /* Taşmayı önler */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Gölgeler ekle */
  display: flex; /* Flexbox kullanarak içeriği hizala */
}

.rounded-image {
  width: 100%; /* Resmi genişlet */
  height: auto; /* Yüksekliği otomatik ayarla */
  border-radius: 10px 0 0 10px; /* Kart köşelerine uyacak şekilde yuvarla */
  object-fit: cover; /* Resmin orantılı görünmesi için */
}

.news-detail-content {
  padding: 20px; /* İçerik alanının iç boşluğu */
  display: flex; /* İçeriği hizala */
  flex-direction: column; /* Dikey hizalama */
  justify-content: center; /* Dikeyde ortala */
  align-items: center; /* Yatayda sola hizala */
}

.news-title {
  font-size: 1.75rem; /* Başlık boyutunu büyüt */
  font-weight: bold; /* Kalın yazı tipi */
  color: #333; /* Başlık rengi */
}

/* Responsive Design */
@media (max-width: 768px) {
  .news-detail-page h1 {
      font-size: 1.5rem;
      text-align: center; /* Başlık merkezde */
  }

  .news-detail-card {
      flex-direction: column; /* Kart içeriği dikey hizala */
  }

  .news-detail-card img {
      border-radius: 10px; /* Resmi tam köşe yuvarla */
  }
}
