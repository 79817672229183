/* MessageOfPresidentEdit.css */

.president-message-container {
  background-color: #f8f9fa; /* Light background color */
  padding: 20px; /* Padding around the container */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */

}
/* Styles for the form title */
.president-message-container h1 {
  font-size: 24px; /* Font size for the title */
  font-weight: bold; /* Bold font weight */
  margin-bottom: 20px; /* Spacing below the title */
}

/* Input field styles */
.president-message-container .form-control {
  margin-bottom: 15px; /* Spacing between input fields */
}

/* Button styles */
.president-message-container .btn.small-button {
  width: auto; /* Auto width for smaller button */
  padding: 5px 10px; /* Reduced padding for smaller button */
}

/* Loading message styles */
.loading-message {
  text-align: center; /* Centered loading message */
  font-size: 20px; /* Font size for the loading message */
  margin-top: 20px; /* Margin above the loading message */
}

/* Responsive styling for smaller devices */
@media (max-width: 768px) {
  .president-message-container {
      padding: 1.5rem;
  }

  h1 {
      font-size: 1.8rem;
  }

  p {
      font-size: 1rem;
  }
}
