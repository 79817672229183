/* GalleryList.css */

.gallery-list-container {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    /* Light background for the container */
    border-radius: 8px;
    /* Rounded corners for the container */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

.gallery-list-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    /* Title font size */
    color: #333;
    /* Title color */
}

.gallery-list-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.gallery-list-table th,
.gallery-list-table td {
    border: 1px solid #ccc;
    /* Border for table cells */
    padding: 12px;
    /* Padding inside table cells */
    text-align: left;
    /* Align text to the left */
}

.gallery-list-table th {
    background-color: #007bff;
    /* Blue background for table headers */
    color: #fff;
    /* White text for table headers */
    font-weight: bold;
    font-size: 14px;
    /* Bold font for headers */
}

.gallery-list-table tr:nth-child(even) {
    background-color: #f2f2f2;
    /* Alternate row color for better readability */
}

.gallery-list-table tr:hover {
    background-color: #e9ecef;
    /* Highlight row on hover */
}

.gallery-list-table input {
    width: 100%;
    padding: 8px;
    font-size: 12px;
    box-sizing: border-box;
}

.gallery-list-table td {
    padding: 8px;
    font-size: 12px;
    box-sizing: border-box;
}

.btn-gallery {
    background-color: transparent;
    /* Transparent background for buttons */
    border: none;
    /* No border for buttons */
    cursor: pointer;
    /* Pointer cursor on hover */
    color: #007bff;
    border-radius: 50%;

    /* Button text color */
    font-size: 16px;
    /* Button font size */
}

.btn-gallery i {
    margin: 0 5px;
    /* Spacing around icons */
}

.btn-gallery:hover {
    color: #ffffff;
    /* Darker color on hover */
    border-radius: 50%;
    text-decoration: underline;
    /* Underline effect on hover */
}

.btn-news:focus {
    outline: none;
    /* Remove default outline on focus */
}
.gallery-image {
    width: 100px; /* Fixed width */
    max-width: 100%; /* Ensures image scales within its container */
    height: auto; /* Keeps aspect ratio */
    border-radius: 5px; /* Rounded corners */
    object-fit: cover; /* Ensures the image fills its space properly */
  }
@media (max-width: 768px) {
    .btn-gallery {
        padding: 3px;
        /* Reduce padding on smaller screens */
    }

    .btn-gallery i {
        font-size: 1.2rem;
        /* Smaller icon size on mobile */
    }

    .gallery-list-table td,
    .gallery-list-table th {
        font-size: 14px;
    }

    .gallery-list-table td input,
    .gallery-list-table td select {
        padding: 6px;
    }

    .gallery-list-table td button {
        padding: 6px 8px;
    }
}

/* Input and select styles */
input[type="text"],
select {
    width: 100%;
    /* Full width for input and select */
    padding: 10px;
    /* Padding inside input/select */
    margin: 5px 0;
    /* Margin around input/select */
    border: 1px solid #ccc;
    /* Border for input/select */
    border-radius: 4px;
    /* Rounded corners */
}

input[type="text"]:focus,
select:focus {
    border-color: #007bff;
    /* Border color on focus */
    outline: none;
    /* Remove default outline */
}