.announcements-page {
  padding: 2rem;
}

.card img {
  max-height: 200px;
  object-fit: cover;
}

.btn-primary {
  margin-top: 10px;
}
