/* Shared button styles for SignIn and SignUp components */

.creative-signin-btn, .creative-signup-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50px; /* Rounded edges */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease;
  border: none;
  background: linear-gradient(45deg, #ff4b2b, #774e59); /* Gradient background */
  color: white;
  cursor: pointer;
}

.creative-signin-btn:hover, .creative-signup-btn:hover {
  background: linear-gradient(45deg, #774e59, #ff4b2b); /* Gradient flip on hover */
  transform: translateY(-2px); /* Subtle lift effect */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

/* Adjust form elements (optional) */
.form-label {
  font-weight: bold;
}

input.form-control {
  padding: 10px;
  border-radius: 4px;
}
