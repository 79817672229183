/* General styling for the Contact container */
.contact-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  font-weight: bold;
}

p {
  font-size: 1.1rem;
  color: #555;
}

/* Form styling */
form {
  padding: 1rem 0;
}

form .form-control {
  padding: 0.75rem;
  border-radius: 5px;
}

button[type="submit"] {
  padding: 0.75rem;
  font-size: 1.1rem;
}

/* Responsive styling for smaller devices */
@media (max-width: 768px) {
  .contact-container {
      padding: 1.5rem;
  }

  h1 {
      font-size: 1.8rem;
  }

  p {
      font-size: 1rem;
  }
}
