/* Admin Panel Genel Stil */
.admin-panel {
    padding: 20px;
    background-color: #f8f9fa; /* Panel için açık arka plan */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
}

/* Başlıklar */
h2, h3 {
    color: #343a40; /* Koyu renk başlıklar */
    margin-bottom: 15px;
}

/* Margin Bottom */
.mb-3 {
    margin-bottom: 1rem; /* Tutarlı boşluklar */
}

/* Liste Elemanları */
.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #ffffff; /* Beyaz arka plan */
}

/* Liste Elemanlarındaki Metin */
.list-group-item span {
    flex: 1;
}

/* Butonlar */
button {
    margin-left: 10px;
    transition: opacity 0.3s ease-in-out; /* Pürüzsüz geçiş */
}

/* Buton Hover Durumu */
button:hover {
    opacity: 0.9; /* Hafif hover efekti */
}

/* Form Grupları */
.form-group {
    margin-bottom: 15px;
}

/* Form Kontrolleri */
.form-control {
    border-radius: 5px; /* Yuvarlatılmış köşeler */
}

/* Tab İçeriği */
.tab-content {
    margin-top: 20px; /* Tab içerikleri arasındaki boşluk */
}

/* Sekme Bağlantıları */
.nav-tabs .nav-link {
    color: #495057; /* Varsayılan sekme rengi */
    padding: 10px 15px;
    border: 1px solid transparent;
    border-radius: 5px 5px 0 0;
    transition: all 0.3s ease-in-out; /* Pürüzsüz geçiş */
}

/* Sekme Hover Durumu */
.nav-tabs .nav-link:hover {
    background-color: #ffe5e5; /* Yumuşak kırmızı hover arka plan rengi */
    color: #d9534f; /* Kırmızı hover metin rengi */
    border: 1px solid #d9534f; /* Kırmızı hover kenarlık */
    border-bottom: none; /* Alt kenarlık yok */
}

/* Aktif Sekme */
.nav-tabs .nav-link.active {
    background-color: #d9534f; /* Aktif sekme arka plan rengi */
    color: white; /* Aktif sekme metin rengi */
    border: 1px solid #d9534f; /* Aktif sekme kenarlığı */
    border-bottom: none; /* Alt kenarlık yok */
}

/* Sekme İçerik Alanı */
.tab-content {
    border: 1px solid #dee2e6; /* İçerik kenarlığı */
    padding: 15px;
    border-radius: 0 5px 5px 5px; /* Yuvarlatılmış köşeler */
    background-color: #fff; /* Beyaz içerik arka planı */
}

/* Responsive Tasarım */
@media (max-width: 768px) {
    .admin-panel {
        padding: 15px; /* Küçük cihazlarda daha az padding */
    }

    h2, h3 {
        font-size: 1.5rem; /* Mobilde daha küçük başlıklar */
    }

    .list-group-item {
        flex-direction: column; /* Liste elemanlarını mobilde üst üste diz */
        align-items: flex-start;
    }

    button {
        margin-left: 0; /* Butonlar arasındaki margin'i sıfırla */
        margin-top: 5px; /* Mobilde butonların üstüne boşluk ekle */
    }

    .nav-tabs .nav-link {
        padding: 8px 10px; /* Mobilde sekmeler için daha az padding */
        font-size: 14px; /* Mobilde daha küçük yazı */
    }

    .tab-content {
        padding: 10px; /* İçerik için daha az padding */
    }
}
