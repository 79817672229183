/* Announcements List */
.announcements-list {
  text-align: left;
  margin: 20px;
  min-height: 500px;
  padding: 15px; /* Padding azaltıldı */
  background-color: #ffffff; /* Beyaz arka plan rengi */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Daha belirgin gölge */
  position: relative;
  overflow: hidden; /* Prevent overflow */
}

/* Announcement Link */
.announcement-link {
  text-decoration: none;
  color: inherit;
  display: block;
  margin-bottom: 1rem;
}

/* Announcement Item */
.announcement-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px; /* Padding azaltıldı */
  border-radius: 6px; /* Köşe yarıçapı azaltıldı */
  background-color: #f8f9fa;
  transition: background-color 0.3s ease, transform 0.5s ease; /* Transition and slide animation */
}

/* Hover effect */
.announcement-item:hover {
  background-color: #e9ecef; /* Hover rengi */
}

/* Slide Animation */
.slide-enter {
  transform: translateX(100%); /* New slide enters from the right */
  opacity: 0; /* Initially invisible */
}

.slide-enter-active {
  transform: translateX(0); /* Slide returns to normal position */
  opacity: 1; /* Visibility increases */
  transition: transform 0.5s ease, opacity 0.5s ease; /* Animation duration */
}

.slide-exit {
  transform: translateX(0); /* Starting position when exiting */
  opacity: 1; /* Visible */
}

.slide-exit-active {
  transform: translateX(-100%); /* Exits to the left */
  opacity: 0; /* Becomes invisible */
  transition: transform 0.5s ease, opacity 0.5s ease;
}

/* Arrow Icon */
.arrow-icon {
  font-size: 1.25rem; /* Küçük boyut */
  color: #999;
}

/* Announcement Date */
.announcement-date {
  font-size: 0.8rem; /* Küçük boyut */
  color: #999;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px; /* Margin azaltıldı */
}

/* Pagination Buttons */
.pagination-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px; /* Padding azaltıldı */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; /* Renk geçişi */
}

/* Button Hover State */
.pagination-controls button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .announcements-list {
    margin: 10px;
    padding: 10px;
  }

  .announcement-item {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
  }

  .announcement-title {
    font-size: 1.1rem; /* Daha küçük başlık */
  }

  .announcement-detail {
    font-size: 0.85rem; /* Daha küçük detay metni */
  }

  .arrow-icon {
    font-size: 1rem; /* Daha küçük ok ikonu */
    margin-top: 5px; /* Yukarı boşluk */
  }

  .announcement-date {
    font-size: 0.7rem; /* Daha küçük tarih fontu */
  }

  .pagination-controls {
    flex-direction: column; /* Stack pagination controls vertically */
    align-items: center; /* Center align items */
  }

  .pagination-controls button {
    width: 100%; /* Full width buttons */
    margin-top: 10px; /* Space between buttons */
  }
}
