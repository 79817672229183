/* Genel Sayfa Düzeni */
.home-page {
  margin: 0 auto;
}

/* Genel Kenar Boşluğu */
.section-content {
  padding-left: 10%;  /* Kenar boşluklarını azaltıyoruz */
  padding-right: 10%;
  text-align: center;
}

.section-content h2,
.section-content h4 {
  margin-bottom: 1rem;
  font-weight: bold;
}

/* Carousel resim boyutu */
.carousel-image {
  object-fit: cover;
}

@media (max-width: 768px) {
  .carousel-image {
    height: 250px;
  }
}

/* Başlık ve bar */
.section-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
}

.title-bar {
  width: 100px;
  height: 4px;
  background-color: #007bff;
  margin: 0 auto 20px auto;
  border-radius: 2px;
}

/* Haberler kart stili */
.news-item {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
}

/* Banner alanı */
.banner {
  width: 100%;
  height: 300px;  /* Banner yüksekliği küçültüldü */
  position: relative;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;

  background-image: url('../assets/banner.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  box-shadow: inset 0 0 100px rgba(255, 255, 255, 0.7);
}

/* Banner içeriği */
.banner h2,
.banner p {
  z-index: 1;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

/* Galeri stili */
.gallery-image {
  height: 300px;  /* Galeri boyutları optimize edildi */
  object-fit: cover;
}

@media (max-width: 768px) {
  .gallery-image {
    height: 200px;
  }
}

/* Video stil */
.video-container {
  text-align: center;
  margin-bottom: 20px;
}

.news-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.news-card:hover {
  transform: scale(1.05);
}

.news-card img {
  height: 200px;
  object-fit: cover;
}



/* Mobil cihazlar için banner alanı */
@media (max-width: 768px) {
  .banner {
    height: auto; /* Yüksekliği içeriğe göre ayarla */
    min-height: 200px; /* En az 200px yükseklik */
    background-size: contain; /* Görüntüyü sığdır */
    padding: 15px;
  }

  .banner h2, .banner p {
    font-size: 1.2rem; /* Yazı boyutunu küçült */
  }
}