.announcement-list-container {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  /* Light background for the container */
  border-radius: 8px;
  /* Rounded corners for the container */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}

.announcement-list-container h1 {
  margin-bottom: 20px;
  font-size: 20px;
  /* Title font size */
  color: #333;
  /* Title color */
}

.announcement-list-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.announcement-list-table th,
.announcement-list-table td {
  border: 1px solid #ccc;
  /* Border for table cells */
  padding: 12px;
  /* Padding inside table cells */
  text-align: left;
  /* Align text to the left */
}

.announcement-list-table th {
  background-color: #007bff;
  /* Blue background for table headers */
  color: #fff;
  /* White text for table headers */
  font-weight: bold;
  font-size: 14px;

  /* Bold font for headers */
}

.announcement-list-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.announcement-list-table tr:hover {
  background-color: #e9ecef;
}

.announcement-list-table input {
  width: 100%;
  padding: 8px;
  font-size: 12px;
  box-sizing: border-box;
}

.announcement-list-table td {
  padding: 8px;
  font-size: 12px;
  box-sizing: border-box;
}

.btn-announcement {
  background-color: transparent;
  /* Transparent background for buttons */
  border: none;
  /* No border for buttons */
  cursor: pointer;
  /* Pointer cursor on hover */
  color: #007bff;
  border-radius: 50%;

  /* Button text color */
  font-size: 16px;
  /* Button font size */
}

.btn-announcement i {
  margin: 0 5px;
  /* Spacing around icons */
}

.btn-announcement:hover {
  color: #ffffff;
  /* Darker color on hover */
  border-radius: 50%;
  text-decoration: underline;
  /* Underline effect on hover */
}

.btn-announcement:focus {
  outline: none;
  /* Remove default outline on focus */
}

@media (max-width: 768px) {
  .btn-announcement {
      padding: 3px;
      /* Reduce padding on smaller screens */
  }

  .btn-announcement i {
      font-size: 1.2rem;
      /* Smaller icon size on mobile */
  }

  .announcement-list-table td,
  .announcement-list-table th {
      font-size: 14px;
  }

  .announcement-list-table td input,
  .announcement-list-table td select {
      padding: 6px;
  }

  .announcement-list-table td button {
      padding: 6px 8px;
  }
}