.branches-container {
    padding: 20px;
}

.branch-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.branch-card:hover {
    transform: scale(1.02);
}

.branch-card .card-img-top {
    height: 150px;
    object-fit: cover;
}

.branch-card .card-body {
    text-align: center;
}