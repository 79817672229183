/* Genel Navbar stil */
.navbar {
  background-color: #f8f9fa;
}

.navbar-brand img {
  width: 251px; /* Increase logo width */
  height: auto;
}


.nav-item {
  color: #555;
  margin-left: 15px;
}

.nav-item:hover {
  color: #007bff;
  text-decoration: none;
}

.navbar-toggler {
  border: none;
}

/* Profil kısmı */
.profile-name {
  display: inline; /* Varsayılan olarak isim gösterilir */
  margin-left: 5px;
}

/* Küçük ekranlarda kullanıcı ismini gizler */
@media (max-width: 768px) {
  .profile-name {
      display: none; /* Sadece profil ikonu kalır */
  }
}

/* Profil resmi yuvarlak ve kenar çizgili */
.rounded-circle {
  border: 2px solid #ddd;
}

/* Giriş ve kayıt ol butonları */
.creative-signin-btn, .creative-signup-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: none;
}

.creative-signin-btn {
  background: linear-gradient(45deg, #774e59, #ff4b2b);
  color: white;
}

.creative-signin-btn:hover {
  background: linear-gradient(45deg, #ff4b2b, #774e59);
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.creative-signup-btn {
  background: linear-gradient(45deg, #ff4b2b, #774e59);
  color: white;
}

.creative-signup-btn:hover {
  background: linear-gradient(45deg, #774e59, #ff4b2b);
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.bi {
  margin-right: 8px;
  font-size: 1.2em;
}
