/* Genel responsive ayarlar */
.video-container {
    width: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
}

.video-thumbnail {
    width: 100%;
    height: auto;
    display: block;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: white;
    pointer-events: none;
}

/* Modal yapılandırması */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background: #fff;
    max-width: 80vw;
    max-height: 80vh;
    overflow: hidden;
}

.modal-iframe {
    width: 100%;
    height: 100%;
}

.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    color: #333;
    font-size: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.modal-close:hover {
    background-color: rgba(255, 0, 0, 0.9);
    transform: scale(1.1);
    color: white;
}

.modal-close:before {
    content: '×';
    font-size: 1.8rem;
}

.video-container-modal {
    position: relative;
    width: 80%;
    max-width: 900px;
    height: 60vh;
    max-height: 500px;
    margin: 0 auto;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-container-modal iframe {
    width: 100%;
    height: 100%;
    border: none;
}

/* Media queries for responsiveness */

/* 1200px ve üzeri */
@media (min-width: 1200px) {
    .play-icon {
        font-size: 3rem;
    }

    .modal-content {
        max-width: 70vw;
        max-height: 70vh;
    }
}

/* 992px - 1200px */
@media (max-width: 1200px) and (min-width: 992px) {
    .play-icon {
        font-size: 2.5rem;
    }

    .modal-content {
        max-width: 75vw;
        max-height: 75vh;
    }
}

/* 768px - 992px */
@media (max-width: 992px) and (min-width: 768px) {
    .play-icon {
        font-size: 2rem;
    }

    .modal-content {
        max-width: 80vw;
        max-height: 80vh;
    }
}

/* Mobil cihazlar için (768px ve altı) */
@media (max-width: 768px) {
    .play-icon {
        font-size: 1.5rem;
    }

    .modal-content {
        max-width: 90vw;
        max-height: 90vh;
    }

    .video-container-modal {
        width: 100%;
        height: 50vh; /* Daha küçük ekranlar için video yüksekliğini düşürelim */
    }
}
