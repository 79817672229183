/* Carousel Container */
.news-image-carousel {
  width: 100%;
  margin: 0 auto;
}

/* Carousel Content */
.news-carousel-content {
  display: flex;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #b6b5b5 0%, #f3e8e8 80%);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.news-carousel-content:hover {
  transform: scale(1.03);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Carousel Image Container */
.news-carousel-image-container {
  flex: 2;
  max-width: 60%;
  margin-right: 20px;
  overflow: hidden;
  /* Taşmayı önler */
  position: relative;
  /* Konumlandırma için gerekli */
  height: 40vh;
  /* Yükseklik viewport yüksekliğinin %40'ı */
  min-height: 200px;
  /* Minimum yükseklik */
  max-height: 400px;
  /* Maksimum yükseklik */
}




.news-gallery-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Resmi tam göstermek için */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.news-gallery-image:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Carousel Details */
.news-carousel-details {
  flex: 1;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Title styling */
.news-carousel-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Description styling */
.news-carousel-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  max-height: 3em;
  /* Maksimum yükseklik */
  overflow: hidden;
  /* Taşmayı önler */
  text-overflow: ellipsis;
  /* Üç nokta ile ifade et */
  white-space: nowrap;
  /* Tek satırda tut */
  text-decoration: none;
  /* Altını çizili yapmayı önler */
}

/* Carousel okları için stil */
.news-image-carousel .carousel-control-prev,
.news-image-carousel .carousel-control-next {
    background-color: rgba(0, 0, 0, 0.5); /* Arka plan rengi */
    color: #ffffff; /* Ok rengi */
    border-radius: 50%; /* Daire şeklinde yap */
    width: 40px; /* Genişliği ayarlayın */
    height: 40px; /* Yüksekliği ayarlayın */
    display: flex; /* İçeriği merkezde hizalamak için */
    align-items: center; /* Dikey merkezleme */
    justify-content: center; /* Yatay merkezleme */
    position: absolute; /* Mutlak konumlandırma */
    top: 50%; /* Dikey ortalama */
    transform: translateY(-50%); /* Dikey olarak merkezleme */
}

/* Soldaki okun konumu */
.news-image-carousel .carousel-control-prev {
    left: 10px; /* Soldan boşluk */
}

/* Sağdaki okun konumu */
.news-image-carousel .carousel-control-next {
    right: 10px; /* Sağdan boşluk */
}

/* Okların hover durumunda rengi */
.news-image-carousel .carousel-control-prev:hover,
.news-image-carousel .carousel-control-next:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Hover durumu için daha koyu renk */
}

/* Okların içeriklerini ayarlayın */
.news-image-carousel .carousel-control-prev-icon,
.news-image-carousel .carousel-control-next-icon {
    background-color: transparent; /* Arka planı şeffaf yap */
    width: 16px; /* Ok ikonunun genişliği */
    height: 16px; /* Ok ikonunun yüksekliği */
}


/* Responsive Design */
@media (max-width: 768px) {
  .news-gallery-image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }

  .news-carousel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* Yazıları merkeze hizala */
  }

  .news-carousel-image-container {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .news-carousel-details {
    max-width: 100%;
    margin-top: 10px;
  }

  .news-carousel-title {
    font-size: 1.2rem;
  }

  .news-carousel-description {
    font-size: 0.9rem;
    max-height: 4em;
    /* Mobilde biraz daha fazla yükseklik */
  }
}
