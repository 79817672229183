.donate-container {
  padding: 2rem;
  justify-self: center;
  max-width: 1400px; /* Daha geniş düzen için max genişlik artırıldı */
  margin: 0 auto; /* Yatayda merkeziyet */
  text-align: center;
}

/* Kartlar için genel stil */
.info-section {
  background-color: #fff;
  padding: 1.8rem; /* Kartların iç boşluğu genişletildi */
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
}

/* Bilgi kutusu */
.info-box {
  padding: 1.2rem; /* Daha geniş iç boşluk */
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
}

.info-box p {
  margin-bottom: 0;
}

/* Başlıklar */
h4 {
  margin-top: 1rem;
  font-weight: bold;
}

/* Kartlar arası boşluklar ve genişlik ayarları */
.mb-4 {
  margin-bottom: 2rem !important;
}

@media (max-width: 768px) {
  .info-section {
    padding: 1.2rem; /* Daha dar cihazlarda boşluk azaltıldı */
  }
  h4 {
    font-size: 1.2rem;
  }
  .info-box {
    padding: 0.9rem;
  }
}

@media (max-width: 576px) {
  .info-section {
    padding: 1rem; /* Mobil için optimize edilmiş iç boşluk */
  }
  h4 {
    font-size: 1rem;
  }
  .info-box {
    padding: 0.75rem;
  }
}


/* Responsive ayarlar */
@media (max-width: 768px) {
  h4 {
    font-size: 1.2rem;
  }
  .info-box {
    padding: 0.75rem;
  }
}

