.donate-container {
  margin: 20px;
}

.summary {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f9f9f9;
}

.summary h3 {
  margin-bottom: 10px;
}

.text-danger {
  font-size: 0.875em;
  color: red;
}
