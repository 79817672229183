/* Profile picture styling */
.card-img-top {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.profile-header .profile-img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.profile-header .profile-img:hover {
  transform: scale(1.05);
}

/* Card and form styling */
.card {
  border-radius: 15px;
  overflow: hidden;
}

.card-header {
  background-color: #f8f9fa;
  font-weight: bold;
}

.form-control {
  border-radius: 10px;
  border: 1px solid #ced4da;
  padding: 12px;
  box-shadow: none;
  transition: all 0.3s ease;
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.shadow-sm {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/* Button styling */
button {
  transition: background-color 0.2s, border-color 0.2s;
}

button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.card-text {
  font-size: 1rem;
  color: #6c757d;
}

button:disabled {
  background-color: #6c757d;
}

body {
  background-color: #f0f2f5;
}

.btn-custom {
  padding: 0.5rem 1rem;
  margin: 0.2rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.btn-custom:hover {
  background-color: #0056b3;
  color: white;
}

.btn-custom:focus {
  box-shadow: none;
}
