.image-carousel {
    max-width: 800px; /* Slider genişliğini ayarlayın */
    margin: 0 auto; /* Ortala */
}

.carousel-image-container {
    display: flex;
    justify-content: center; /* Resimleri yatay olarak ortala */
    align-items: center; /* Dikey olarak ortala */
    height: 400px; /* Yükseklik ayarı */
    overflow: hidden; /* Taşan görüntüleri gizle */
}

.carousel-image {
    width: auto; /* Genişliği otomatik ayarlayın */
    max-width: 100%; /* Maksimum genişliği %100 yapın */
    max-height: 100%; /* Maksimum yüksekliği %100 yapın */
    object-fit: contain; /* Resimlerin orantısını koru */
    border-radius: 8px; /* Kenar yuvarlama */
    transition: transform 0.3s ease; /* Hover efekti için geçiş */
}

.carousel-image:hover {
    transform: scale(1.05); /* Hover durumunda büyütme efekti */
}
