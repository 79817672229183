.go-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.go-to-top.visible {
    opacity: 1;
    visibility: visible;
}

.go-to-top:hover {
    background-color: #0056b3;
}