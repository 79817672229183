/* Shared button styles for SignIn and SignUp components */
.creative-signin-btn, .creative-signup-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50px; /* Rounded edges */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease;
  border: none;
  background: linear-gradient(45deg, #ff4b2b, #774e59); /* Gradient background */
  color: white;
  cursor: pointer;
  width: 100%; /* Full width for button */
  margin-top: 10px; /* Form elemanları arasında boşluk */
}

.creative-signin-btn:hover, .creative-signup-btn:hover {
  background: linear-gradient(45deg, #774e59, #ff4b2b);
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

/* Form element adjustments */
.form-label {
  font-weight: bold;
}

input.form-control {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%; /* Full width for input elements */
}

.input-group .btn {
  padding: 10px 15px;
}

.alert {
  margin-top: 10px;
  font-size: 14px;
}

.card {
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 30px;
}

