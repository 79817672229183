.unauthorized-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
}

.unauthorized-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.unauthorized-container p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
