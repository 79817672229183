.news-page {
  padding: 2rem;
}

.card img {
  .card img {
    width: 100%;       /* Görselin kartın genişliğini tamamen kaplamasını sağlar */
    height: 150px;     /* Yüksekliği sabitleyerek dikdörtgen görünüm elde edebilirsiniz */
    object-fit: cover; /* Görselin belirtilen alana sığması için kırpma uygular */
  }
  
}

.btn-primary {
  margin-top: 10px;
}
