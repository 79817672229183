.news-list-container {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  /* Light background for the container */
  border-radius: 8px;
  /* Rounded corners for the container */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}
.news-list-container h1 {
  margin-bottom: 20px;
  font-size: 20px;
  /* Title font size */
  color: #333;
  /* Title color */
}
.news-list-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.news-list-table th,
.news-list-table td {
  border: 1px solid #ccc;
  /* Border for table cells */
  padding: 12px;
  /* Padding inside table cells */
  text-align: left;
  /* Align text to the left */
}

.news-list-table th {
  background-color: #007bff;
  /* Blue background for table headers */
  color: #fff;
  /* White text for table headers */
  font-weight: bold;
  font-size: 14px;

  /* Bold font for headers */
}

.news-list-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.news-list-table tr:hover {
  background-color: #e9ecef;
}

.news-list-table input {
  width: 100%;
  padding: 8px;
  font-size: 12px;
  box-sizing: border-box;
}
.news-list-table td {
  padding: 8px;
  font-size: 12px;
  box-sizing: border-box;
}
.btn-news {
  background-color: transparent;
  /* Transparent background for buttons */
  border: none;
  /* No border for buttons */
  cursor: pointer;
  /* Pointer cursor on hover */
  color: #007bff;
  border-radius: 50%;

  /* Button text color */
  font-size: 16px;
  /* Button font size */
}
.btn-news i {
  margin: 0 5px;
  /* Spacing around icons */
}
.btn-news:hover {
  color: #ffffff;
  /* Darker color on hover */
  border-radius: 50%;
  text-decoration: underline;
  /* Underline effect on hover */
}
.btn-news:focus {
  outline: none;
  /* Remove default outline on focus */
}

.news-image {
  width: 100px; /* Fixed width */
  max-width: 100%; /* Ensures image scales within its container */
  height: auto; /* Keeps aspect ratio */
  border-radius: 5px; /* Rounded corners */
  object-fit: cover; /* Ensures the image fills its space properly */
}
/* Spinner için stil */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #007bff; /* spinner rengini buradan değiştirebilirsiniz */
  margin: 20px 0;
}

.loading-spinner i {
  font-size: 40px;
  margin-right: 10px;
  color: #007bff;
  animation: spin 1s linear infinite; /* Dönen animasyon */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Yükleniyor metni */
.loading-spinner p {
  font-size: 20px;
  font-weight: bold;
  color: #007bff;
}

@media (max-width: 768px) {
  .btn-news {
      padding: 3px;
      /* Reduce padding on smaller screens */
  }

  .btn-news i {
      font-size: 1.2rem;
      /* Smaller icon size on mobile */
  }

  .news-list-table td,
  .news-list-table th {
      font-size: 14px;
  }

  .news-list-table td input,
  .news-list-table td select {
      padding: 6px;
  }

  .news-list-table td button {
      padding: 6px 8px;
  }
}
input[type="text"],
input[type="email"],
textarea,
select {
  width: 100%;
  padding: 12px; /* Increased padding for better user experience */
  margin: 4px 0 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  resize: vertical;
  min-height: 80px; /* Minimum height for the textarea */
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333; /* Darker text color for better contrast */
}

.news-image {
  width: 100px; /* Adjust the size as needed */
  height: auto;
  border-radius: 5px; /* Add border radius for images */
}
@media (max-width: 768px) {
  .btn-news {
      padding: 3px;
      /* Reduce padding on smaller screens */
  }

  .btn-news i {
      font-size: 1.2rem;
      /* Smaller icon size on mobile */
  }
}