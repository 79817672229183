/* BankAccounts.css */

.bank-accounts-container {
    padding: 2rem;
}

.account-card {
    border-radius: 8px;
    overflow: hidden;
}

.account-card .card-body {
    padding: 1.5rem;
}

.account-card .card-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.account-card .card-text {
    font-size: 1rem;
}