/* Modal.css */

.modal-content {
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 90%;
  max-height: 80%;
  margin: auto;
  overflow: hidden;
}


.modal-video iframe {
  width: 100%;
  height: 100%;
}

.modal-image {
  width: 100%;
  height: auto; /* Yükseklik otomatik olarak ayarlanır */
  max-height: 80vh; /* Modal içindeki yüksekliği sınırla */
  object-fit: cover; /* Resmin kutuya sığmasını sağlar */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  /* Arka plan rengi */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 1.5rem;
  cursor: pointer;
}

