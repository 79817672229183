.haklar-container {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  /* Light background for the container */
  border-radius: 8px;
  /* Rounded corners for the container */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}


.haklar-list {
  list-style-type: none;
  /* Remove default list styles */
  padding: 0;
  /* Remove default padding */
}

.hak-item:hover {
  transform: scale(1.02);
}

.card {
  border: 1px solid #e0e0e0;
  /* Light border */
}

/* Modal Styles */
.modal-dialog {
  max-width: 800px;
  /* Adjust the width as needed */
}

.modal-content {
  height: 600px;
  /* Adjust the height as needed */
  text-align: left;
}

.modal-header .close {
  display: none;
  /* Hide the close button */
}

.hak-item {
  margin-bottom: 15px;
  /* Adjust spacing between items */
  padding: 10px;
  /* Add space between items */
  transition: transform 0.2s;
  display: flex;
  /* Use flexbox for alignment */
  flex-direction: column;
  /* Stack elements vertically */
  justify-content: space-between;
  /* Space out elements */
}

.card-body {
  display: flex;
  /* Use flexbox for card body */
  flex-direction: column;
  /* Stack elements vertically */
  justify-content: space-between;
  /* Space out elements */
  height: 100%;
  /* Ensure full height */
}

.btn-primary {
  align-self: flex-end;
  /* Align button to the right */
  opacity: 0.8;
  /* Make button slightly transparent */
  transition: opacity 0.2s;
  /* Smooth transition for hover */
  padding: 5px 10px;
  /* Decrease button padding */
  font-size: 0.9rem;
  /* Decrease button text size */
}

.btn-primary:hover {
  opacity: 1;
  /* Full opacity on hover */
}

.haklar-header h2 {
  font-size: 1.5rem;
  /* Decrease the size of the header */
  margin-bottom: 5px;
  /* Adjust spacing */
}

.haklar-description {
  font-size: 0.9rem;
  /* Decrease the size of the description */
}

.card-title {
  font-size: 1rem;
  /* Decrease the card title size */
}