.footer {
  background-color: #f8f9fa;
  padding: 40px 0;
  margin-top: auto;
  position: relative; /* Footer'ı referans noktası yapar */
}

.footer h5 {
  font-weight: bold;
  margin-bottom: 15px;
}

.footer p {
  margin-bottom: 10px;
}

.footer .list-unstyled {
  padding-left: 0;
}

.footer .list-unstyled li {
  margin-bottom: 10px;
}

.footer .list-unstyled a {
  color: #000;
  text-decoration: none;
}

.footer .list-unstyled a:hover {
  text-decoration: underline;
}

.footer .social-icons {
  display: flex;
  flex-direction: column;
}

.footer .social-icons a {
  color: #000;
  margin-bottom: 10px;
  font-size: 30px;
}

.footer .social-icons a:hover {
  color: #007bff;
}

.footer .text-center {
  text-align: center;
}
/* Ekran genişliği 768px'den küçük olduğunda sosyal ikonları yatay hizala */
@media (max-width: 768px) {
  .footer .social-icons {
    flex-direction: row; /* Küçük ekranlarda yatay sıraya geç */
    justify-content: center; /* İkonları ortalar */
  }

  .footer .social-icons a {
    margin: 0 10px; /* İkonlar arasında yatay boşluk bırak */
  }
}